import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/banners/expert-advice-banner/expert-advice-card.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/banners/hero-banner/index.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/banners/hero-banner/x-small.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/banners/offer/offer-deal-component.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/banners/product-carousel-banner/dy-product-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/banners/product-carousel-banner/product-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/banners/product-carousel-banner/wishlist-product-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/banners/vendor-banner/vendor-banner.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/brands/brand-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/categories/categories-carousel.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/component-container/single-offer-handler.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/homepage-popup-order/middleware-popups.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/stripe/index.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/cms/trending-searches/index.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/contentful-link.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/media.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/product-item.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/recommendations/recomendation.css");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/components/swiper/base-swiper.tsx");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/node_modules/.pnpm/next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/node_modules/.pnpm/next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/home/jenkins/agent/workspace/nahdi_web_build_main/node_modules/.pnpm/next@14.0.0_@babel+core@7.24.4_@opentelemetry+api@1.9.0_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-no-ssr.js")